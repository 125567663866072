import React from "react";
import "./Products.css";
import { motion } from 'framer-motion';

// Importing images from the assets folder
import EnterpriseImage from "../../../../assets/enterprise.png";
import NanoImage from "../../../../assets/nano.png";
import CloudImage from "../../../../assets/kobanCloud.png";
import NanoVImage from "../../../../assets/nanoV.png";
import VisualizerImage from "../../../../assets/visualizer.png";


const productsData = [
  {
    id: 1,
    title: "Enterprise",
    description:
      "The definitive edition that combines network operation monitoring and intranet security functions.",
    image: EnterpriseImage,
    link: "https://www.cysol.co.jp/index.php/en/netskatekoban/products/netskatekoban-enterprise",
  },
  {
    id: 2,
    title: "Nano",
    description:
      "Monitors device connection status 24 hours/365 days a year. Protects your network from unauthorized connections.",
    image: NanoImage,
    link: "https://www.cysol.co.jp/index.php/en/netskatekoban/products/netskatekoban-nano",
  },
  {
    id: 3,
    title: "Koban Cloud",
    description:
      "The powerful security and network management functions of the Enterprise model can now be provided as a cloud service.",
    image: CloudImage,
    link: "https://www.cysol.co.jp/index.php/en/netskatekoban/products/netskatekoban-cloud",
  },
  {
    id: 4,
    title: "Nano V",
    description:
      "A new model that supports VLAN while retaining the features of Nano. It can support both segmented networks and networks configured with VLANs.",
    image: NanoVImage,
    link: "https://www.cysol.co.jp/index.php/en/netskatekoban/products/netskatekoban-nanov",
  },
  {
    id: 5,
    title: "Visualizer",
    description:
      "NetSkate Visualizer is an innovative Network monitoring/management software that enables visualization of Network configuration and shows the status of various devices in the network at a glance..",
    image: VisualizerImage,
    link: "https://www.cysol.co.jp/index.php/en/products/visualizer",
  },
  
];

const openPopup = (url, event) => {
  event.preventDefault(); // Prevent the default link behavior
  window.open(
    url,
    '_blank',
    'width=800,height=800,scrollbars=yes,resizable=yes'
  );
};

const Products = () => {
  
  return (
    <div id="products-section" className="products-section">
      <motion.h3 className="products-heading" 
                 initial = {{opacity: 0, scale: 0.5}}                    
                 transition={{duration:0.5, ease: "easeInOut",}}
                 whileInView={{ opacity: 1, scale: 1 }}
                 viewport={{ once: true }}>Products</motion.h3>
      <div className="products-container">
        {productsData.map((product,index) => (
          <motion.div 
            className="product-card" 
            key={product.id}
            initial = {{opacity: 0}}                    
            whileInView={{ opacity: 1}}
            transition={{duration:0.5, ease: "easeInOut",delay: index * 0.2}}
            viewport={{ once: true }}>
            <img src={product.image} alt={product.title} className="product-image" />
            <h3>{product.title}</h3>
            <p>{product.description}</p>
            <a href={product.link} className="product-button" onClick={(event) => openPopup(product.link, event)}>
              {product.title}
            </a>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default Products;
