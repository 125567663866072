import React from "react";
import "./ServicePage.css";
import MainSection from "./servicePageComponents/mainSection/MainSection";
// import SideSection from "./servicePageComponents/sideSection/SideSection";
import Header from "../../utils/header/Header";
import Footer from "../../utils/footer/Footer";
import TopSection from "./servicePageComponents/topSection/TopSection";

const ServicePage = () => {
    return(
        <>
            <Header/>
            <TopSection/>
            {/* <div className="ServicePage-Content">
                <MainSection/>
                <SideSection/>
            </div> */}
            <MainSection/>
            <Footer/>
        </>
    )
}

export default ServicePage;