import React, { useState, useEffect } from "react";
import './SliderSection.css';
import cysol_image_01 from "../../../../assets/cysol_images_01.jpg";
import cysol_image_02 from "../../../../assets/cysol_images_02.jpg";
import cysol_image_03 from "../../../../assets/cysol_images_03.jpg";

const SliderSection = () =>  {
  const ImageSliderData = [
    {
        image: cysol_image_01,
        text: 'Contributing to society through a blend of technological innovation and creative imagination'
    },
    {
        image: cysol_image_02,
        text: 'Continuing to grow with the development of network technology'
    },
    {
      image: cysol_image_03,
      text:"CyberKoban ​​Solutions is a company aiming for technological innovation in the Internet"
    }
  ];
  

  const [current, setCurrent] = useState(0);
  const length = ImageSliderData.length;

  const nextSlide =() => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  }

  // const prevSlide = () => {
  //   setCurrent(current === 0 ? length - 1 : current - 1);
  // };

  // Auto-slide functionality using useEffect
  useEffect(() => {
    const autoSlide = setInterval(nextSlide, 5000); 

    return () => clearInterval(autoSlide); // Cleanup on component unmount
  }, [current, length]); // Rerun the effect if `current` or `length` changes


  if (!Array.isArray(ImageSliderData) || ImageSliderData <= 0) {
    return null;
  }

  return (
    <section className='slider'>
    {ImageSliderData.map((ImageSliderData, index) => {
      return (
        <div
          className={index === current ? 'slide active' : 'slide'}
          key={index}
        >
          {index === current && (
            <>
              <img src={ImageSliderData.image} alt='' className='image' />
              <div className="image-text">
               <p>{ImageSliderData.text}</p>
              </div>
            </>
          )}
          
        </div>
        
      );
    })}
  </section>
  );
}

export default SliderSection;