import React from "react";
import "./MainSection.css";
import { motion } from 'framer-motion';

const MainSection = () => {
  return (
    <div className="MainSection-Container">
      {/* Navigation Menu */}
      <nav className="nav-menu">
        <ul>
          <li>
            <a href="#our-expertise">Our Expertise</a>
          </li>
          <li>
            <a href="#why-choose-cykosols">Why Choose CyKoSols</a>
          </li>
          <li>
            <a href="#technology-stack">CyKoSols Technology Stack</a>
          </li>
        </ul>
      </nav>

      {/* Content Sections */}
      <div className="content-section">
        <motion.h2
        id="our-expertise"
        className="content-heading"
        initial = {{opacity: 0, scale: 0.5}}                    
        transition={{duration:1, ease: "easeInOut",}}
        whileInView={{ opacity: 1, scale: 1 }}
        viewport={{ once: true }}>Our Expertise</motion.h2>
        <motion.h3 
          className="content-heading2"
          initial = {{opacity: 0, scale: 0.5}}                    
          transition={{duration:1, ease: "easeInOut",}}
          whileInView={{ opacity: 1, scale: 1 }}
          viewport={{ once: true }}>1. Comprehensive IT Services</motion.h3>
        <motion.p initial = {{opacity: 0, scale: 0.5}}                    
                  transition={{duration:1.5, ease: "easeInOut",}}
                  whileInView={{ opacity: 1, scale: 1 }}
                  viewport={{ once: true }}>
            CyKoSols provides end-to-end software solutions tailored to meet the specific needs of businesses, ensuring enhanced productivity, scalability, and security. Our core services are in the area of <b>Network Security & IT Infrastructure Management,</b> building robust and secure systems that grow with your business. We also specialize in developing custom software and mobile applications tailored to meet the unique needs of diverse industries.
        </motion.p>
        
        <motion.h3 
            className="content-heading2"
            initial = {{opacity: 0, scale: 0.5}}                    
          transition={{duration:1, ease: "easeInOut",}}
          whileInView={{ opacity: 1, scale: 1 }}
          viewport={{ once: true }}>2. Advanced Network Management</motion.h3>
        <motion.p   
        initial = {{opacity: 0, scale: 0.5}}                    
        transition={{duration:1.5, ease: "easeInOut",}}
        whileInView={{ opacity: 1, scale: 1 }}
        viewport={{ once: true }}>We specialize in creating manageable, monitorable, and scalable network systems for businesses of all sizes.<br/>
        </motion.p>
        <motion.ol type="a"
          initial = {{opacity: 0, scale: 0.5}}                    
          transition={{duration:1.5, ease: "easeInOut",}}
          whileInView={{ opacity: 1, scale: 1 }}
          viewport={{ once: true }}>
          <li>Mapping multi-vendor networks using open protocols (SNMP).</li>
          <li>Designing and implementing Management Information Base (MIB) modules for enhanced reliability and security.</li>
          <li>Developing custom solutions for real-time data monitoring and analysis.</li>
        </motion.ol>
        
        <motion.h3 
                    className="content-heading2"
                    initial = {{opacity: 0, scale: 0.5}}                    
                    transition={{duration:1, ease: "easeInOut",}}
                    whileInView={{ opacity: 1, scale: 1 }}
                    viewport={{ once: true }}>3. Intranet Security & Asset Management</motion.h3>
        <motion.p 
        initial = {{opacity: 0, scale: 0.5}}                    
        transition={{duration:1.5, ease: "easeInOut",}}
        whileInView={{ opacity: 1, scale: 1 }}
        viewport={{ once: true }}>Our tailored solutions secure critical assets for SMBs and large enterprises, ensuring business continuity and compliance.
        </motion.p>

        <motion.h2
        id="why-choose-cykosols"
        className="content-heading"
        initial = {{opacity: 0, scale: 0.5}}                    
        transition={{duration:1, ease: "easeInOut",}}
        whileInView={{ opacity: 1, scale: 1 }}
        viewport={{ once: true }}>Why Choose CyKoSols</motion.h2>
        <motion.div 
        initial = {{opacity: 0, scale: 0.5}}                    
        transition={{duration:1.5, ease: "easeInOut",}}
        whileInView={{ opacity: 1, scale: 1 }}
        viewport={{ once: true }}>At CyKoSols, our <b>development philosophy</b> centres around our clients. We are committed to:
        <br/>
          <ol>
              <motion.li
              initial = {{opacity: 0, scale: 0.5}}                    
              transition={{duration:1.5, ease: "easeInOut",}}
              whileInView={{ opacity: 1, scale: 1 }}
              viewport={{ once: true }}> Designing software that adapts to your unique needs.<br/></motion.li>
              <motion.li 
              initial = {{opacity: 0, scale: 0.5}}                    
              transition={{duration:1.5, ease: "easeInOut",}}
              whileInView={{ opacity: 1, scale: 1 }}
              viewport={{ once: true }}>Enhancing productivity while reducing operational costs.<br/></motion.li>
              <motion.li 
              initial = {{opacity: 0, scale: 0.5}}                    
              transition={{duration:1.5, ease: "easeInOut",}}
              whileInView={{ opacity: 1, scale: 1 }}
              viewport={{ once: true }}>Delivering user-friendly, scalable, and high-performing solutions.<br/></motion.li>
              <motion.li 
              initial = {{opacity: 0, scale: 0.5}}                    
              transition={{duration:1.5, ease: "easeInOut",}}
              whileInView={{ opacity: 1, scale: 1 }}
              viewport={{ once: true }}>Offering fast, reliable, and collaborative customer support.<br/></motion.li>
          </ol>
        </motion.div>
        
        <motion.h2
        id="technology-stack"
        className="content-heading"
        initial = {{opacity: 0, scale: 0.5}}                    
        transition={{duration:1, ease: "easeInOut",}}
        whileInView={{ opacity: 1, scale: 1 }}
        viewport={{ once: true }}>CyKoSols Technology Stack</motion.h2>
        
        <motion.p 
        initial = {{opacity: 0, scale: 0.5}}                    
        transition={{duration:1.5, ease: "easeInOut",}}
        whileInView={{ opacity: 1, scale: 1 }}
        viewport={{ once: true }}>We stay ahead of the curve with expertise in the latest technologies:<br/>
        </motion.p>
        <ol>
          <motion.li 
          initial = {{opacity: 0, scale: 0.5}}                    
          transition={{duration:1.5, ease: "easeInOut",}}
          whileInView={{ opacity: 1, scale: 1 }}
          viewport={{ once: true }}><b>Programming Languages: </b>Java, C/C++, Perl, Python, and more.<br/>
          </motion.li>
          
          <motion.li 
          initial = {{opacity: 0, scale: 0.5}}                    
          transition={{duration:1.5, ease: "easeInOut",}}
          whileInView={{ opacity: 1, scale: 1 }}
          viewport={{ once: true }}> <b>Web Development: </b>Proficient in React, JavaScript, HTML, and CSS.<br/>
          </motion.li>

          <motion.li
          initial = {{opacity: 0, scale: 0.5}}                    
          transition={{duration:1.5, ease: "easeInOut",}}
          whileInView={{ opacity: 1, scale: 1 }}
          viewport={{ once: true }}><b>Database Systems: </b>MySQL, PostgreSQL, MS-SQL, Oracle and SQLite.<br/>
          </motion.li>

          <motion.li 
          initial = {{opacity: 0, scale: 0.5}}                    
          transition={{duration:1.5, ease: "easeInOut",}}
          whileInView={{ opacity: 1, scale: 1 }}
          viewport={{ once: true }}><b>Protocols: </b>TCP/IP, SNMP, SOAP/XML for seamless communication.<br/>
          </motion.li>

          <motion.li 
          initial = {{opacity: 0, scale: 0.5}}                    
          transition={{duration:1.5, ease: "easeInOut",}}
          whileInView={{ opacity: 1, scale: 1 }}
          viewport={{ once: true }}><b>Platforms & Devices: </b>Windows, Unix, Linux<br/>
          </motion.li>
        </ol>
      </div>
    </div>
  );
};

export default MainSection;
