import React, { useState, useRef, useEffect } from 'react';
import "./CompanySection.css";
import { motion } from 'framer-motion';
import CKSCafeteria from '../../../../assets/CKSCafeteria.jpg';
import CKSEntrance from '../../../../assets/CKSEntranceplate.jpg';
import CKSLib from '../../../../assets/CKSLib.jpg';
import CKSNamePlate from '../../../../assets/CKSNamePlate.jpg';
import CKSOfficeEntry from '../../../../assets/CKSOfficeEntry.jpg';
import CKSReception from '../../../../assets/CKSReception.jpg';
import SobhaBuilding from '../../../../assets/SobhaBuilding.jpg';
import CKSConference from '../../../../assets/CKSConference.jpg';
import CKSConference1 from '../../../../assets/CKSConference1.jpg';
import CKSWorkspace from '../../../../assets/CKSWorkspace.jpg';
import CKSWorkspace1 from '../../../../assets/CKSWorkspace1.JPG';

const CompanySection = () => {
  const images = [
    CKSConference,
    CKSCafeteria,
    CKSWorkspace1,
    CKSEntrance,
    CKSConference1,
    SobhaBuilding,
    CKSLib,
    CKSWorkspace,
    CKSNamePlate,
    CKSOfficeEntry,
    CKSReception,
  ];

  const carouselRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [maxScrollWidth, setMaxScrollWidth] = useState(0);

  useEffect(() => {
    const updateMaxScrollWidth = () => {
      if (carouselRef.current) {
        setMaxScrollWidth(
          carouselRef.current.scrollWidth - carouselRef.current.clientWidth
        );
      }
    };

    updateMaxScrollWidth();

    window.addEventListener("resize", updateMaxScrollWidth);
    return () => window.removeEventListener("resize", updateMaxScrollWidth);
  }, []);

  const handleScroll = () => {
    if (carouselRef.current) {
      setScrollPosition(carouselRef.current.scrollLeft);
    }
  };

  const scrollLeft = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({
        left: -200, 
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({
        left: 200,
        behavior: "smooth",
      });
    }
  };

  // Event listener to track scroll position
  useEffect(() => {
    const ref = carouselRef.current;
    if (ref) {
      ref.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (ref) {
        ref.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  return (
    <div className="CompanySection-container">
      <motion.div
        className="CompanySection-top"
        initial={{ opacity: 0, scale: 0.5 }}
        transition={{ duration: 1, ease: "easeInOut" }}
        whileInView={{ opacity: 1, scale: 1 }}
        viewport={{ once: true }}
      >
        <h2>Company Photos</h2>
        <div className="CompanySection-Horizontalbar"></div>
      </motion.div>
      <motion.div
        className="multi-carousel-container"
        initial={{ opacity: 0, scale: 0.5 }}
        transition={{ duration: 1, ease: "easeInOut" }}
        whileInView={{ opacity: 1, scale: 1 }}
        viewport={{ once: true }}
      >
        <div className="carousel" ref={carouselRef}>
          {images.map((image, index) => (
            <div className="carousel-item" key={index}>
              <img src={image} alt={`Slide ${index + 1}`} onContextMenu={(e) => e.preventDefault()} />
            </div>
          ))}
        </div>
      </motion.div>
      <motion.div
        className="carousel-navigation"
        initial={{ opacity: 0, scale: 0.5 }}
        transition={{ duration: 1, ease: "easeInOut" }}
        whileInView={{ opacity: 1, scale: 1 }}
        viewport={{ once: true }}
      >
        <button
          className={`nav-button ${
            scrollPosition <= 0 ? "disabled" : ""
          }`}
          onClick={scrollLeft}
          disabled={scrollPosition <= 0}
        >
          &lt; Previous
        </button>
        <button
          className={`nav-button ${
            scrollPosition >= maxScrollWidth ? "disabled" : ""
          }`}
          onClick={scrollRight}
          disabled={scrollPosition >= maxScrollWidth}
        >
          Next &gt;
        </button>
      </motion.div>
    </div>
  );
};

export default CompanySection;
