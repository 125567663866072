import React from 'react'
import AboutPageSection from './aboutPageComponents/aboutPageSection/AboutPageSection';
import Header from '../../utils/header/Header';
import Footer from '../../utils/footer/Footer';
import MissionSection from './aboutPageComponents/missionSection/MissionSection';
import PeopleSection from './aboutPageComponents/peopleSection/PeopleSection';
import CompanySection from './aboutPageComponents/companySection/CompanySection';


const AboutPage = () => {
    return(
        <>
            <Header/>
            <AboutPageSection/>
            <MissionSection/>
            <PeopleSection/>
            <CompanySection/>
            <Footer/>
        </>
    )
}

export default AboutPage;