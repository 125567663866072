import React from "react";
import "./CareerTopSection.css";
import Image from "../../../../assets/cysol_images_01.jpg";

const CareerTopSection = () => {
    return (
        <div className="career-container">
            <div className="text-section">
                <h1>You Deserve The Best Career</h1>
            </div>
            <div className="image-section">
                <img
                    src={Image} /* Replace with your image path */
                    alt="Team standing together"
                />
            </div>
        </div>
        );
};

export default CareerTopSection;