import React from "react";
import "./ServicesSection.css";
import { motion } from 'framer-motion';

const ServicesSection = () => {
    const serviceSectionData = [
        {
            id: 1,
            title: "Our Expertise",
            link: "/services#our-expertise",
        },{
            id: 2,
            title: "Why Choose CyKoSols",
            link: "/services#why-choose-cykosols",
        },
        {
            id: 3,
            title: "CyKoSols Technology Stack",
            link: "/services#technology-stack",
        },
    ]

    return (
        <div className="Services-main">
            <motion.h3
                className="Services-main-header"
                initial = {{opacity: 0, scale: 0.5}}                    
                transition={{duration:1, ease: "easeInOut",}}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true }}>
                    Services
            </motion.h3>
            <motion.div 
                className="Services-horizontal-row"
                initial = {{opacity: 0, scale: 0.5}}                    
                transition={{duration:1, ease: "easeInOut",}}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true }}
                ></motion.div>
            <motion.p
                initial = {{opacity: 0, scale: 0.5}}                    
                transition={{duration:1, ease: "easeInOut",}}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true }}>
            Software is increasingly becoming the backbone of the business. Organizations need reliable software development partner, who can think from the customer perspective and provide effective and efficient solutions. The success or efficiency of the business is driven by the software in operation.
CyberKoban Solutions has always been providing solutions from the customers perspective with the customer involvement. CyKoSols realizes the business need of IT applications and develops software according to that. CyKoSols works with customers closely to eliminate the expectation mismatch for the software. CyKoSols team at customer location will help in achieving this objective and drive the software development. This will minimize the cost of software development to the customer. Our software can be installed, maintained and understood by end users with ease and the solutions will have an excellent user interface and reporting. CyKoSols has been an ODC for Cyber Solutions, Japan for that past 10 years. With the process refined with the Japanese customer, CyKoSols is geared to take up the next level.

            </motion.p>
            <div className="Service-cards">
                <div className="services-container">
                    {serviceSectionData.map((service,index) => (
                    <motion.div 
                        className="service-card" 
                        key={service.id}
                        initial = {{opacity: 0}}                    
                        whileInView={{ opacity: 1}}
                        transition={{duration:1, ease: "easeInOut",delay: index * 0.2}}
                        viewport={{ once: true }}> 
                        <h3>{service.title}</h3> 
                        <a href={service.link} className="service-button">
                            Know More
                        </a>
                    </motion.div>
                    ))}
                </div>
            </div> 

        </div>
    )
}

export default ServicesSection;