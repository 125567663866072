import React from "react";
import Header from "../../utils/header/Header";
import Footer from "../../utils/footer/Footer";
import ContactUs from "../contactPage/contactPageComponents/contactUsSection/ContactUsSection"
import ContactFormSection from "./contactPageComponents/contactFormSection/ContactFormSection";

const ContactPage =() =>{
    return(
    <>
            <Header/>
            <ContactUs />
            <ContactFormSection />
            <Footer/>
    </>
    )
}


export default ContactPage;