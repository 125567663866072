import React from "react";
import "./TopSection.css";
import Image from "../../../../assets/ServiceTopImage.png";

const TopSection = () => {
    return (
        <div className="servicesContainer">
            <div className="topContainer">
                <img src={Image} alt="topImage" className="topImage" />
                {/* <p className="tagline">CyKoSols don’t deliver just software, <br />
                <span className="centered-text">It delivers value with IT.</span>
                </p> */}


                <p className="tagline">CyKoSols delivers innovation <br />
                <span className="centered-text">and value through IT.</span>
                </p>
            </div>
        </div>

    )
}

export default TopSection;