import React from "react";
import './AboutSection.css';
import AboutUsImage from "../../../../assets/AboutUsImage.png";
import { motion } from 'framer-motion';

const AboutSection = () => {
    return(
        <div className="About-Section">
            <div className="About-main">
                <motion.h3 initial = {{opacity: 0, scale: 0.5}}                    
                    transition={{duration:1.5, ease: "easeInOut",}}
                    whileInView={{ opacity: 1, scale: 1 }}
                    viewport={{ once: true }} >
                    About CyberKoban Solutions
                </motion.h3>
                <motion.div
                    initial = {{opacity: 0, scale: 0.5}}
                    transition={{duration:2, ease: "easeInOut",}}
                    whileInView={{ opacity: 1, scale: 1 }}
                    viewport={{ once: true }} 
                    className="horizontal-row">
                </motion.div>
                <div className="About-section-content"> 
                    <motion.p
                        initial = {{opacity: 0, scale: 0.5}}
                        transition={{duration:2.25, ease: "easeInOut",}}
                        whileInView={{ opacity: 1, scale: 1 }}
                        viewport={{ once: true }}>
                        At <b>CyberKoban Solutions Pvt. Ltd.,</b> we combine decades of expertise with cutting-edge technology to deliver transformative IT solutions. Established in 2024, CyKoSols emerged as a key player in Network Security Management by acquiring Intelligent Software Solutions Pvt. Ltd. (ISS), a pioneering IT company founded in 1994. This strategic integration has strengthened our capabilities and united our team of highly skilled professionals under one mission: Empowering businesses through innovative software solutions.

Backed by our parent company, <b>Cyber Solutions Inc., Japan, </b>we bring a global perspective and a commitment to excellence. With our roots firmly planted in India’s thriving IT ecosystem, CyKoSols leverages advanced technology to meet the diverse and evolving needs of our clients.
                    </motion.p>
                    <div className="about-us-image">
                        <motion.img 
                            initial = {{opacity: 0, scale: 0.5}}
                            transition={{duration:2.25, ease: "easeInOut",}}
                            whileInView={{ opacity: 1, scale: 1 }}
                            viewport={{ once: true }} 
                            src={AboutUsImage} alt=""/>
                    </div>
                </div>
            </div>           
        </div>
    );
};

export default AboutSection;