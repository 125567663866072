import React from "react";
import Header from "../../utils/header/Header";
import AboutSection from "./homePageComponents/aboutSection/AboutSection";
import Products from "./homePageComponents/productsSection/Products";
import Footer from "../../utils/footer/Footer";
import ServicesSection from "./homePageComponents/servicesSection/ServicesSection";
import SliderSection from "./homePageComponents/sliderSection/SliderSection";

const HomePage =() =>{
    return(
    <>
            <Header/>
            <SliderSection/>
            <AboutSection/>
            <Products />
            <ServicesSection/>
            <Footer />
    </>
    )
}


export default HomePage;