import React from "react";
import "./Footer.css";

const Footer = () => {
    return (
        <div className="footer">
            <p className="footerContent">
            2-A, 3rd Floor, Sobha Alexander Plaza, 16/2 Commissariat Road, Bengaluru 560025, India <br />
            The contents of this website are subject to change without prior notice. <br />
            Brand names or product names mentioned on this website are trademarks or registered trademarks of their respective owners. <br />
            Ph no: 080-41124688, 080-25320505 <br />
            © CyberKoban Solutions Pvt Ltd. <br />
            </p>
        </div>
    );
}
export default Footer;