import React, { useState } from "react";
import "./Header.css";
import logo from "../../assets/CyKoSolsLogo.png";
import logoName from "../../assets/CyKoSolsName.png";
import { CiGlobe } from "react-icons/ci";
import { GiHamburgerMenu } from "react-icons/gi"; // Hamburger icon
import { IoClose } from "react-icons/io5"; // Close icon

const Header = () => {
    const [menuOpen, setMenuOpen] = useState(false); // State for menu visibility

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const closeMenu = () => {
        setMenuOpen(false);
    }

    return (
        <div>
            <div className="top-header">
                <nav className="top-nav-links">
                    <a href="/about-us" className="nav-link">About Us</a>
                    <a href="/contact-us" className="nav-link">Contact Us</a>
                </nav>
            </div>
            <div className="bottom-header">
                <div className="logo-container">
                    <a href="/">
                        <img src={logo} alt="Logo" className="logo" />
                        <img src={logoName} alt="LogoName" className="logoName" />
                    </a>
                </div>
                <GiHamburgerMenu className="hamburger-icon" onClick={toggleMenu} />
                <nav className="bottom-nav-links">
                    <a href="/" className="nav-link">Home</a>
                    <a href="/services" className="nav-link">Services</a>
                    <a href="/#products-section" className="nav-link">Products</a>
                    <a href="/careers" className="nav-link">Careers</a>
                    <CiGlobe size={20} className="ci-globe" />
                </nav>
            </div>

            {/* Hamburger menu */}
            <div className={`hamburger-menu ${menuOpen ? "active" : ""}`}>
                <IoClose className="close-icon" onClick={toggleMenu} />
                <a href="/" className="nav-link" onClick={closeMenu}>Home</a>
                <a href="/about-us" className="nav-link" onClick={closeMenu}>About Us</a>
                <a href="/#products-section" className="nav-link" onClick={closeMenu}>Products</a>
                <a href="/services" className="nav-link" onClick={closeMenu}>Services</a>
                <a href="/careers" className="nav-link" onClick={closeMenu}>Careers</a>
                <a href="/contact-us" className="nav-link" onClick={closeMenu}>Contact Us</a>
                </div>
            </div>
    );
};

export default Header;