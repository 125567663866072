import React, { useState } from "react";
import emailjs from "emailjs-com";
import "./ContactFormSection.css";

const ContactFormSection = () => {
  const [formData, setFormData] = useState({
    email: "",
    contactNumber: "",
    firstName: "",
    lastName: "",
    comments: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    emailjs
      .send(
        "service_r82d1s8",  // Replace with your actual service ID
        "template_w6qyapa", // Replace with your actual template ID
        formData,
        "r9Au8eCJe5oqff22M"      // Replace with your actual user ID/Public Key
      )
      .then(
        (result) => {
          alert("Email sent successfully!");
          setFormData({
            email: "",
            contactNumber: "",
            firstName: "",
            lastName: "",
            comments: "",
          });
        },
        (error) => {
          alert("Failed to send email. Please try again later.");
        }
      );
  };

  return (
    <div className="contact-form-section">
      <h1>Contact Form</h1>
      <p>Have a project in mind or need guidance for your IT needs? Connect with us today, and let’s shape the future together.</p>
      <form onSubmit={handleSubmit}>
        <label>Email Address: <span className="required">*</span></label>
        <input
          type="email"
          name="email"
          placeholder="Enter email"
          value={formData.email}
          onChange={handleChange}
          required
        />

        <label>Contact Number: <span className="required">*</span></label>
        <input
          type="tel"
          name="contactNumber"
          placeholder="Enter contact number"
          value={formData.contactNumber}
          onChange={handleChange}
          required
        />

        <label>First Name: <span className="required">*</span></label>
        <input
          type="text"
          name="firstName"
          placeholder="Enter first name"
          value={formData.firstName}
          onChange={handleChange}
          required
        />

        <label>Last Name: <span className="required">*</span></label>
        <input
          type="text"
          name="lastName"
          placeholder="Enter last name"
          value={formData.lastName}
          onChange={handleChange}
          required
        />

        <label>Comments:</label>
        <textarea
          name="comments"
          placeholder="Enter your comments"
          rows="4"
          value={formData.comments}
          onChange={handleChange}
        />
        <button className="formButton" type="submit">
          Submit
        </button>
      </form>
    </div>
  );
};

export default ContactFormSection;
