import React from 'react'
import './MissionSection.css';
import { motion } from 'framer-motion';
import MissionImage from "../../../../assets/OurMissionImage.png";

const MissionSection = () => {
    return(
        <>
            <div className='MissionSection-container'>
                <motion.div className='MissionSection-content'
                    initial = {{opacity: 0, scale: 0.5}}                    
                    transition={{duration:1, ease: "easeInOut",}}
                    whileInView={{ opacity: 1, scale: 1 }}
                    viewport={{ once: true }} >
                    <h2>Our Mission</h2>
                    <div className='MissionSection-Horizontalbar'></div>
                    <p>At CyberKoban Solutions, our mission is to empower organizations with advanced, reliable network security solutions that safeguard their digital infrastructure. By blending decades of industry expertise with the latest technology, we deliver innovative IT solutions that drive transformation, ensuring a secure and resilient future for our clients.</p>                
                </motion.div>
                <div className='MissionImage-container'>
                <motion.img src={MissionImage} alt=''
                    initial = {{opacity: 0, scale: 0.5}}                    
                    transition={{duration:1, ease: "easeInOut",}}
                    whileInView={{ opacity: 1, scale: 1 }}
                    viewport={{ once: true }} />
                </div>
            </div>
        </>
    )
}

export default MissionSection;