import React from "react";
import Header from "../../utils/header/Header";
import CareerTopSection from "./careersPageComponents/topCareerSection/CareerTopSection";
import CareerContentSection from "./careersPageComponents/contentCareerSection/CareerContentSection";
import Footer from "../../utils/footer/Footer";
const CareerPage =() =>{
    return(
    <>
            <Header/>
            <CareerTopSection />
            <CareerContentSection />
            <Footer />
    </>
    )
}


export default CareerPage;