import React from 'react';
import "./PeopleSection.css";
import { motion } from 'framer-motion';
import PeopleImage from "../../../../assets/OurPeopleImage.png";

const PeopleSection = () => {
    return (
        <>
            <div className='PeopleSection-container'>
                <div className='PeopleImage-container'>
                <motion.img src={PeopleImage} alt=''
                    initial = {{opacity: 0, scale: 0.5}}                    
                    transition={{duration:1, ease: "easeInOut",}}
                    whileInView={{ opacity: 1, scale: 1 }}
                    viewport={{ once: true }}/>
                </div>
                <motion.div className='PeopleSection-content'
                    initial = {{opacity: 0, scale: 0.5}}                    
                    transition={{duration:1, ease: "easeInOut",}}
                    whileInView={{ opacity: 1, scale: 1 }}
                    viewport={{ once: true }}>
                    <h2>Our People</h2>
                    <div className='PeopleSection-Horizontalbar'></div>
                    <p>Our employees thrive on making a difference - with passion, intelligence, and the desire to protect people from the digital bad guys. Our employees challenge themselves and each other to develop, enhance, and promote award-winning solutions throughout the world in a culture of creativity and collaboration.</p>                
                </motion.div>
            </div>
        </>
    )
}

export default PeopleSection;