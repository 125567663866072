import React from "react";
import { motion } from 'framer-motion';
import "./CareerContentSection.css";

const CareerContentSection = () => {
    return (
        <div className="main-content">
            <motion.h2
                initial = {{opacity: 0, scale: 0.5}}                    
                transition={{duration:1, ease: "easeInOut",}}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true }}>Career Information</motion.h2>
            <motion.div className="careerinfo-content"
                initial = {{opacity: 0, scale: 0.5}}                    
                transition={{duration:1, ease: "easeInOut",}}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true }}>CyKoSols is appropriate place for people who want to work in niche areas. 
                We are in to cutting edge technologies. <br />
                We work on Open Source Softwares. We are looking for people who are:
                <motion.ul
                initial = {{opacity: 0, scale: 0.5}}                    
                transition={{duration:1, ease: "easeInOut",}}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true }}>
                    <li>Enthusiastic</li>
                    <li>Innovative</li>
                    <li>Committed</li>
                    <li>Stable</li>
                    <li>Discplined</li>
                    <li>Quick learner</li>
                </motion.ul>    
            </motion.div>
            <motion.h2
                initial = {{opacity: 0, scale: 0.5}}                    
                transition={{duration:1, ease: "easeInOut",}}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true }}>Requirements</motion.h2>
            <motion.div className="requirement-content"
                initial = {{opacity: 0, scale: 0.5}}                    
                transition={{duration:1, ease: "easeInOut",}}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true }}>
                We are a company specializing in the development and sale of network security products that utilize advanced technology. <br />
                We are looking for people who have experience, knowledge, and motivation in the following:
            <motion.ol
                initial = {{opacity: 0, scale: 0.5}}                    
                transition={{duration:1, ease: "easeInOut",}}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true }}>
                <li>System-related (applications...)</li>
                <li>Internet-related (protocols, security, management, applications)</li>
                <li>Computer-related (internal and user management of Unix, Windows, etc.)</li>
                <li>Programming (Java, C, XML,...)</li>
            </motion.ol>
            </motion.div>
            <motion.div className="inquiries-content"
                initial = {{opacity: 0, scale: 0.5}}                    
                transition={{duration:1, ease: "easeInOut",}}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true }}>
                For further job applications and inquiries, please contact the email address below. <br />
                CyberKoban ​​Solutions Pvt Ltd. Human Resources Department <br />
                sales@cykosols.com
            </motion.div>
        </div>
    );
};

export default CareerContentSection;