import React from 'react';
import { motion } from 'framer-motion';
import "./AboutPageSection.css";
import WhoWeAreImage from "../../../../assets/WhoWeAreImage.png";

const AboutPageSection = () => {
    return (
        <>
            <div className='AboutPage-Container'> 
                <div className='AboutPage-contents'>
                    <div className='AboutPageSection'>
                        <div className='AboutImage-container'>
                        <motion.img src={WhoWeAreImage}  alt=''
                        initial = {{opacity: 0, scale: 0.5}}                    
                        transition={{duration:1, ease: "easeInOut",}}
                        whileInView={{ opacity: 1, scale: 1 }}
                        viewport={{ once: true }}/>
                        </div>
                        <motion.div className='AboutPageInfo'
                        initial = {{opacity: 0, scale: 0.5}}                    
                        transition={{duration:1, ease: "easeInOut",}}
                        whileInView={{ opacity: 1, scale: 1 }}
                        viewport={{ once: true }}>
                            <h2> Who we are</h2>
                            <div className='AboutPage-Horizontalbar'></div>
                            <p>CyberKoban Solutions Pvt. Ltd., established in 2024, emerged as a key player in Network Security Management through the acquisition of Intelligent Software Solutions Pvt. Ltd. (ISS), a trailblazing IT company established in 1994. Our parent company Cyber Solutions Inc. Japan is global leader in Network Security Management with advanced products. This acquisition allows us to blend decades of expertise with the latest technology to provide innovative IT solutions that drive transformation.</p>
                        </motion.div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutPageSection;