import React from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import HomePage from './pages/homePage/HomePage';
import ServicePage from './pages/servicePage/ServicePage';
import AboutPage from './pages/aboutPage/AboutPage';
import CareerPage from './pages/careersPage/CareerPage';
import ContactPage from './pages/contactPage/ContactPage';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<HomePage/>} />
          <Route path='/services' element={<ServicePage/>} />
          <Route path='/about-us' element={<AboutPage/>}/>
          <Route path='/careers' element={<CareerPage/>} />
          <Route path='/contact-us' element={<ContactPage/>} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;