import React from "react";
import "./ContactUsSection.css";

const ContactUsSection = () => {
    return (
        <div className="contact-content">
            <h1>Contact Us </h1>
            <h2>Main Office </h2>
            <p> 2-A, 3rd Floor, Sobha Alexander Plaza, 16/2 Commissariat Road, Bengaluru 560025, India <br /> <br /> 
                Tel No: 080-41124688, 080-25320505 <br />
                Email: sales@cykosols.com
            </p>
        </div>
    );
};

export default ContactUsSection;